.wrapper {
  .garland_wrapper {
    $globe-width: 12px;
    $globe-height: 28px;
    $globe-spacing: 40px;
    $globe-spread: 3px;
    $light-off-opacity: 0.4;
    
    $offset-x: 0px;
    $offset-y: $globe-height / 6;
    $blur-radius: $globe-width * 2;
    $spread-radius: $globe-spread;

    $color-1: #EF2323;
    $color-2: #EFEF23;
    $color-3: #23EF23;

    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    position: fixed;
    z-index: 1;
    margin: -15px 0 0 0;
    padding: 0;
    width: 100%;

    li {
      position: relative;
      animation-fill-mode: both;
      animation-iteration-count: infinite;
      list-style: none;
      margin: 0;
      padding: 0;
      display: block;
      width: $globe-width;
      height: $globe-height;
      border-radius: 50%;
      margin: $globe-spacing / 2;
      display: inline-block;
      animation-name: flash-1;
      animation-duration: 2s;

      &:nth-child(2n+1) {
        animation-name: flash-2;
        animation-duration: 0.4s;
      }

      &:nth-child(4n+2) {
        animation-name: flash-3;
        animation-duration: 1.1s;
      }

      &:nth-child(odd) {
        animation-duration: 1.8s;
      }

      &:nth-child(3n+1) {
        animation-duration: 1.4s;
      }

      &:before {
        content: '';
        position: absolute;
        background: #222;
        width: ($globe-width - 2);
        height: $globe-height / 3;
        border-radius: 3px;
        top: (0 - ($globe-height / 6));
        left: 1px;
      }

      &:after {
        content: '';
        position: absolute;
        top: (0 - $globe-height / 2);
        left: $globe-width - 3;
        width: $globe-spacing + 12;
        height: ($globe-height / 3 * 2);
        border-bottom: solid #222 2px;
        border-radius: 50%;
      }

      &:last-child:after {
        content: none;
      }

      &:first-child {
        margin-left: -$globe-spacing;
      }
    }

    @keyframes flash-1 { 
      0%, 100% {
        background: $color-1;
        box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color-1;
      }
      50% {
        background: rgba($color-1, $light-off-opacity);
        box-shadow: $offset-x $offset-y $blur-radius $spread-radius rgba($color-1, 0.2);
      }
    }

    @keyframes flash-2 { 
      0%, 100% {
        background: $color-2;
        box-shadow: $offset-x $offset-y $blur-radius $spread-radius rgba($color-2, 1);
      }
      50% {
        background: rgba($color-2, $light-off-opacity);
        box-shadow: $offset-x $offset-y $blur-radius $spread-radius rgba($color-2, 0.2);
      }
    }

    @keyframes flash-3 { 
      0%, 100% {
        background: $color-3;
        box-shadow: $offset-x $offset-y $blur-radius $spread-radius rgba($color-3, 1);
      }
      50% {
        background: rgba($color-3, $light-off-opacity);
        box-shadow: $offset-x $offset-y $blur-radius $spread-radius rgba($color-3, 0.2);
      }
    }
  }
}
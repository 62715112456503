$min-device-width: 780px;
$multiplier: 3.4;

.wrapper {
  position: fixed;
  right: 0;
  left: 0;
  top: env(safe-area-inset-top);
  bottom: env(safe-area-inset-bottom);

  display: flex;
  align-items: center;
  justify-content: center;

  .text_wrapper {
    font-style: italic;
    font-weight: bold;
    font-size: $multiplier * 1vw;

    text-transform: uppercase;
    text-align: center;
    text-shadow: 0 0 4px #FBFCFC;
        
    letter-spacing: 6px;

    transition: transform 100ms ease-out 0s,
      text-shadow 500ms ease-out 0s;

    .main_wrapper {
      font-size: 300%;
    }
  }

  .footer_wrapper {
    flex-shrink: 0;
    flex-grow: 0;
    position: absolute;
    bottom: calc(env(safe-area-inset-bottom) + 80px);
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    > a {
      $size: 35px;

      width: $size;
      height: $size;
      margin: 0 $size;
      transition: all 0.1s ease-out;

      &:hover {
        opacity: 0.75;
      }

      > svg {
        width: 100%;
        height: 100%;
        fill: white;

        > path {
          fill: inherit;
        }
      }
    }
  }
}

@media screen and (min-device-width: $min-device-width) {
  .wrapper .text_wrapper {
    font-size: calc(#{$min-device-width} / 100 * #{$multiplier});
  }
}
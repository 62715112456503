@import url('assets/fonts/IBMPlexMono/stylesheet.css');

body {
  margin: 0;
  font-family: 'IBM Plex Mono' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-x pan-y;
  background: #1B1B1B;
  color: #FBFCFC;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
